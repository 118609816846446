import React, { createContext, useCallback, useState } from 'react'
import { ComponentWithChildrenProps } from '../../../Common'

type OrderContextType = {
    redirectUri?: string
    setRedirectUri: ( redirectUri: string ) => void
}

const OrderContextDummy = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setRedirectUri: () => {}
}

export const OrderContext = createContext<OrderContextType>( OrderContextDummy )

export const OrderContextProvider = ({ children }: ComponentWithChildrenProps ) => {
    
    const [ redirectUri, setRedirectUri ] = useState<string>()

    const setRedirectUriHandler = useCallback( ( redirectUri: string ) => setRedirectUri( redirectUri ), [] )

    return(
        <OrderContext.Provider value={{ redirectUri, setRedirectUri: setRedirectUriHandler }}>
            { children }
        </OrderContext.Provider>
    )
}
