import React from 'react'
import loadable from '@loadable/component'

import { Route } from '../Common'

import { Home } from './views/Home'
import { Contact } from './views/Contact'
import { CourseSelection } from './views/CourseSelection'
import { ConfirmEmail } from './views/ConfirmEmail'
import { Unregister } from './views/Unregister'
import { Unsubscribe } from './views/Unsubscribe'
import { ConfirmPayment } from './views/ConfirmPayment'
import { ConfirmOrder } from './views/ConfirmOrder'
import { Login } from '../Auth'

import AUTH_ROUTES from '../Auth/routes'

const Order = loadable( () => import(/* webpackPrefetch: true */ './views/Order' ) )
const Document = loadable( () => import(/* webpackPrefetch: true */ './views/Document' ) )
const Video = loadable( () => import(/* webpackPrefetch: true */ './views/Video' ) )
const Profile = loadable( () => import(/* webpackPrefetch: true */ './views/Profile' ) )
const Course = loadable( () => import(/* webpackPrefetch: true */ './views/Course' ) )
const MyContent = loadable( () => import(/* webpackPrefetch: true */ './views/MyContent' ) )


const AdminOrders = loadable( () => import( '../Admin/views/Orders' ) )
const AdminVouchers = loadable( () => import( '../Admin/views/Vouchers' ) )
const AdminSubscriptions = loadable( () => import( '../Admin/views/Subscriptions' ) )
const AdminContacts = loadable( () => import( '../Admin/views/Contacts' ) )
const AdminDashboard = loadable( () => import( '../Admin/views/Dashboard' ) )

const pageTitleBaseCs = 'Domácí rostlinné sýry, másla, smetany a jogurty'
const pageTitleBaseEn = 'Homemade Plant-Based Cheese, Butter, Cream And Yogurt'

export const ROUTES: Route[] = [
    {
        key: 'home',
        route: '/',
        label: 'home',
        element: <Home />,
        isAuthenticated: false,
        prerender: {
            imageUrl: 'htts://cdn.vegilove.cz/cheeses-2160x1440.jpg',
            langSpecifics: {
                'cs-CZ': {
                    pageTitle: 'Jak snížit stres jídlem - Vegilove.cz',
                    pageDescription: 'Kurz zdarma, v němž se dozvíte rady, jak snížit stres jídlem. Co když je důležitější kdy držíte půst, než co sníte? Proč máte nutkavé chutě na čokoládu, brambůrky či oříšky?'
                },
                'en-US': {
                    pageTitle: 'How to reduce stress - Vegilove.cz',
                    pageDescription: 'Free course to learn tips on how to reduce stress with food. What if when you fast is more important than what you eat? Why do you have compulsive cravings for chocolate, chips, or nuts?'
                }
            }
        }
    },
    {
        key: 'course',
        route: [ '/course', '/kurz' ],
        label: 'kurz',
        element: <Course />,
        isAuthenticated: false,
        prerender: {
            imageUrl: 'https://cdn.vegilove.cz/all-in-one.png',
            langSpecifics: {
                'cs-CZ': {
                    pageTitle: 'Jak vyrobit domácí vegan rostlinný sýr - kurz - Vegilove.cz',
                    pageDescription: 'Naučte se, jak vyrobit domácí vegan rostlinný sýr, veganské máslo, smetany a jogurty. Bez laktózy, za to z ořechů. S tímto kurzem to snadno zvládnete.'
                },
                'en-US': {
                    pageTitle: 'How to make vegan plant-based cheese - the courses - Vegilove.cz',
                    pageDescription: 'Learn how to make homemade plant-based cheese, vegan butter, creams, and yogurts. Lactose-free, made from nuts. You can do it easily with this course.'
                }
            }
        }
    },
    {
        key: 'courseSelection',
        route: [ '/course-selection', '/vyber-kurzu' ],
        label: 'Výběr kurzu',
        element: <CourseSelection />,
        isAuthenticated: false,
        prerender: {
            imageUrl: 'https://cdn.vegilove.cz/online-course.png',
            langSpecifics: {
                'cs-CZ': {
                    pageTitle: 'Kurz jak vyrobit domácí vegan rostlinné sýry - e-book a videokurz - Vegilove.cz',
                    pageDescription: 'Vyberte si e-book nebo videokurz a naučte se vyrobit domácí veganské sýry, máslo, smetany a jogurty. Na 80ti stranách e-booku nebo ve 2 hodinách videokurzu.'
                },
                'en-US': {
                    pageTitle: 'A course on how to make vegan plant-based cheese - e-book and online course - Vegilove.cz',
                    pageDescription: 'Choose an e-book or on-line course to learn how to make homemade vegan cheeses, butter, creams and yogurts.'
                }                
            }
        }
    },
    {
        key: 'order',
        route: [ '/order/:id', '/objednat/:id' ],
        label: 'Order',
        element: <Order />,
        isAuthenticated: false
    },
    {
        key: 'order-confirmation',
        route: [ '/order-confirmation', '/potvrzeni-objednavky' ],
        label: 'Order Confirmation',
        element: <ConfirmOrder />,
        isAuthenticated: false
    },
    {
        key: 'payment-confirmation',
        route: [ '/payment-confirmation/:invoiceNo', '/potvrzeni-platby/:invoiceNo' ],
        label: 'Payment Confirmation',
        element: <ConfirmPayment />,
        isAuthenticated: false
    },
    {
        key: 'download',
        route: [ '/download/:contentId', '/stahnout/:contentId' ],
        label: 'Download',
        element: <Document />,
        isAuthenticated: false // due to free offerings can not be restricted; onetime token does not pass auth
    },
    {
        key: 'play',
        route: [ '/play/:contentId', '/prehrat/:contentId' ],
        label: 'play',
        element: <Video />,
        isAuthenticated: false // due to free offerings can not be restricted; onetime token does not pass auth
    },
    {
        key: 'login',
        route: [ '/log-in', '/prihlasit' ],
        label: 'přihlásit',
        element: <Login />,
        isAuthenticated: false,
        prerender: {
            langSpecifics: {
                'cs-CZ': {
                    pageTitle: `${ pageTitleBaseCs } - přihlášení pro uživatele - Vegilove.cz`
                },
                'en-US': {
                    pageTitle: `${ pageTitleBaseEn } - log-in for subscribers - Vegilove.cz`
                }                
            }
        }
    },
    {
        key: 'mycontent',
        route: [ '/mycontent', '/mujobsah' ],
        label: 'mycontent',
        element: <MyContent />,
        isAuthenticated: true,
    },
    {
        key: 'profile',
        route: [ '/profile', '/profil' ],
        label: 'profile',
        element: <Profile />,
        isAuthenticated: true,
    },
    {
        key: 'contact',
        route: [ '/contact', '/kontakt' ],
        label: 'Contact',
        element: <Contact />,
        isAuthenticated: false,
        prerender: {
            langSpecifics: {
                'cs-CZ': {
                    pageTitle: 'Kontakt - Vegilove.cz',
                    pageDescription: 'Zajímá vás výroba domácích veganských sýrů? Nebojte se nás kotaktovat. Za Vegilove a Božsky jez s.r.o., Michaela Levíčková'
                },
                'en-US': {
                    pageTitle: 'Contact - Vegilove.cz',
                    pageDescription: 'Are you interested how to make homemade vegan cheeses. Feel free to contact us. Vegilove and Božsky jez s.r.o., Michaela Levíčková'
                }                
            }
        }
    },
    {
        key: 'confirm-email',
        route: [ '/confirm-email', '/potvrdit-email' ],
        label: 'Confirm Email',
        element: <ConfirmEmail />,
        isAuthenticated: false,
    },
    {
        key: 'unregister',
        route: [ '/unregister', '/odregistrovat' ],
        label: 'Unregister',
        element: <Unregister />,
        isAuthenticated: false,
    },
    {
        key: 'unsubscribe',
        route: [ '/unsubscribe/:id', '/odhlasit/:id' ],
        label: 'Unregister',
        element: <Unsubscribe />,
        isAuthenticated: false,
    },
    {
        key: 'admin-dashboard',
        route: '/admin/dashboard',
        label: 'Dashboard',
        element: <AdminDashboard />,
        isAuthenticated: true,
    },
    {
        key: 'admin-orders',
        route: '/admin/orders',
        label: 'Orders',
        element: <AdminOrders />,
        isAuthenticated: true,
    },
    {
        key: 'admin-vouchers',
        route: '/admin/vouchers',
        label: 'Vouchers',
        element: <AdminVouchers />,
        isAuthenticated: true,
    },
    {
        key: 'admin-subscriptions',
        route: '/admin/subscriptions',
        label: 'Subscriptions',
        element: <AdminSubscriptions />,
        isAuthenticated: true,
    },
    {
        key: 'admin-contacts',
        route: '/admin/contacts',
        label: 'Contacts',
        element: <AdminContacts />,
        isAuthenticated: true,
    },
    // use auth routes but w/o login which is defined above including prerender info
    ...AUTH_ROUTES.filter( item => item.key !== 'login' )
]
