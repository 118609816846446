import React, { useEffect } from 'react'
//import { QueryClient, QueryClientProvider } from 'react-query'
import { CookieConsentProvider } from '@use-cookie-consent/react'
import { HydrationProvider } from 'react-hydration-provider'

import { I18NContextProvider, Lang } from '../../../I18N'
import { NotificationContextProvider, NotificationService as notificationService } from '../../../Notifications'
import { CookieBanner, ComponentWithChildrenProps, HttpRequestProvider, ModalPromptProvider } from '../../../Common'
import { GTMProvider } from '../../../GTM'

import { CookieConsentDefaultConsent, CookieConsentSupportedTypes, GTM_PARAMS, LANG_LIST } from '../../AppConfig'

import { OrderContextProvider } from '../../contexts/OrderContext'

//const queryClient = new QueryClient()

type AppContextProps = {
    activeLang?: Lang
} & ComponentWithChildrenProps

export const AppContext = ( { activeLang, children }: AppContextProps ) => {

    useEffect(() => {
        document.getElementById( 'loader' )?.remove()
    }, [] )

    return(<>
        {/*<QueryClientProvider client={ queryClient }>*/}
        <React.StrictMode>
            <HydrationProvider>
                <HttpRequestProvider>
                    <I18NContextProvider langList={ LANG_LIST } activeLang={ activeLang }>
                        <CookieConsentProvider useCookieConsentHooksOptions={{ 
                            defaultConsent: CookieConsentDefaultConsent,
                            consentCookieAttributes: {
                                expires: 180,
                                secure: true
                            }
                        }}>
                            <CookieBanner supportedCookieTypes={ CookieConsentSupportedTypes } />
                            <GTMProvider state={ GTM_PARAMS }>
                                <NotificationContextProvider notificationService={ notificationService }>
                                    <ModalPromptProvider>
                                        <OrderContextProvider>
                                            { children }
                                        </OrderContextProvider>
                                    </ModalPromptProvider>
                                </NotificationContextProvider>
                            </GTMProvider>
                        </CookieConsentProvider>
                    </I18NContextProvider>
                </HttpRequestProvider>
            </HydrationProvider>
        </React.StrictMode>
        {/*</QueryClientProvider>*/}
    </>)
}