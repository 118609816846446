import { useState } from 'react'

import { AsyncHttpRequestState, useFetch } from '../../Common'

import { Country } from '../types'

export const useCountries = ( apiUrl: string ): [ Country[], AsyncHttpRequestState<unknown, Country[]>] => {
    const [ countries, setCountries ] = useState<Country[]>( [] )
    const { state } = useFetch<Country[]>({
        url: apiUrl,
        onSuccess: ( state ) => setCountries( state.data || [] )
    })

    return [ countries, state ]
}