import React, { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Ratio, Button } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'

import { LinkButton } from '../../../Common'

import { View } from '../../components/View'
import { DivVW100 } from '../../components/DivVW100'
import { MailTo } from '../../components/MailTo'
import { OrderFree } from '../../components/OrderFree'
import { Radish } from '../../components/Radish'

import 'animate.css'

//import pancaces from '../../../assets/images/pancakes.jpg?format=webp'
import rad7_cs from '../../../assets/images/7-klicovych-pravidel-home.png?format=webp'
import rad7_en from '../../../assets/images/7-key-rules-home.png?format=webp'
import cheeses from '../../../assets/images/cheeses.jpg?format=webp'

export const Home = () => {

    const intl = useIntl()

    // used for CSS Transition
    const nodeRef = useRef( null )
    const freeInputRef = useRef<HTMLInputElement>( null )

    const [ registration, setRegistration ] = useState<'hidden' | 'visible' | 'finished'>( 'hidden' )
    const [ news, setNews ] = useState<'hidden' | 'visible' | 'finished'>( 'visible' )

    //useScrollToAnchor()
    
    return <View className='text-center'>
        {/*<PageComponentHeading title='Home page :)' />*/}
        {/*<DivVW100>
            <Ratio aspectRatio={ 667 / 1000 } className='tile-cover' style={{ backgroundImage: `url('${ pancaces.src }')` }}><>
                <div className='tile-free-course-text text-end'>
                    <h1 style={{ fontSize: 'inherit' }}><FormattedMessage description='App.Home.FreeCourse.Title' defaultMessage='Jak snížit stres jídlem a mít víc energie!' /></h1>
                    <div><FormattedMessage description='App.Home.FreeCourse.SubTitle' defaultMessage='kurz ZDARMA' /></div>
                </div>
            </></Ratio>
        </DivVW100>*/}
        <DivVW100>
            <Ratio aspectRatio={ 1500 / 3000 } className='tile-cover' style={{ backgroundImage: `url('${ intl.locale === 'cs-CZ' ? rad7_cs.src : rad7_en.src }')` }}><>
                <div></div>
            </></Ratio>
        </DivVW100>
        <div className="text-block">
            <div><FormattedMessage description='App.Home.FreeCourse.7rad' defaultMessage='Omezujete maso, vejce a mléčné produkty, ale obáváte se, že vám budou chybět důležité živiny? Hledáte jednoduché a jasné rady, co si ve své stravě hlídat? Nechejte se zdarma inspirovat profesionálními tipy v bleskovém 7denním minikurzu. Každý den dostanete do mailu kraťounké 2-3minutové video s jedním klíčovým pravidlem. Jen klikněte a my vám kurz zdarma pošleme na váš email.' /></div>
        </div>
        { registration !== 'finished' && <>
            <div className='text-block my-5 px-2'>
                <Button type="button" variant='light-brown' onClick={ () => setRegistration( 'visible' ) }><FormattedMessage description='App.Home.FreeCourse.ActionBtn' defaultMessage='chci kurz ZDARMA' /></Button>
            </div>
            <CSSTransition in={ registration === 'visible' } nodeRef={ nodeRef } timeout={ 500 } appear unmountOnExit 
                classNames={{
                    enterActive: 'animate__bounceIn',
                    exitActive: 'animate__bounceOut'
                }}
                onEntered={ () => { if( freeInputRef.current && freeInputRef.current.focus ) freeInputRef.current.focus() } }
            >
                <div ref={ nodeRef } className='text-block text-start p-3'><OrderFree 
                    ref={ freeInputRef }
                    setStatus={ setRegistration }
                    packageId={ 6 }
                /></div>
            </CSSTransition>
        </> }
        { registration === 'finished' && <div className="text-block">
            <div><FormattedMessage description='App.Home.MainCourse.RegFinished.ThankYou' defaultMessage='Děkujeme! Kurz putuje do vaší mailové schránky.' /></div>
            <div className='image-block'><Radish size={ 100 } rotation={ 90 } /></div>
            <div><FormattedMessage description='App.Home.MainCourse.RegFinished.SpamNotice' defaultMessage='Pokud kurz do pár minut nepřijde, mrkněte do spamu a nastavte moji adresu {email} jako přátelskou. Děkuji.' values={{ email: <MailTo /> }} /></div>
        </div> }
        <DivVW100>
            <Ratio aspectRatio={ 667 / 1000 } className='tile-cover' style={{ backgroundImage: `url('${ cheeses.src }')` }}><>
                <div className='text-center tile-main-course-text'>
                    <h2 className='text-uppercase' style={{ fontSize: 'inherit' }}><FormattedMessage description='App.Home.MainCourse.Title' defaultMessage='Domácí rostlinné sýry, máslo, smetany a jogurty' /></h2>
                    <div className="text-white"><FormattedMessage description='App.Home.MainCourse.SubTitle' defaultMessage='Jak si doma snadno a rychle vyrobit 17 skvělých veganských alternativ běžných mléčných výrobků a ušetřit při tom peníze' /></div>
                    <div className="text-block"><LinkButton variant='light' to={ intl.locale === 'cs-CZ' ? '/kurz' : '/course'}><FormattedMessage description='App.Home.MainCourse.ActionBtn' defaultMessage='To potřebuju' /></LinkButton></div>
                </div>
            </></Ratio>
        </DivVW100>
        <div id='#novinky' />
        <div id='#news' />
        { news === 'visible' && <div className='text-block'>
            <div className='pb-5'>
                <div><FormattedMessage description='App.Home.News.T1' defaultMessage='Chcete vědět o všem novém, co se u nás šustne?' /></div>
                <div><FormattedMessage description='App.Home.News.T2' defaultMessage='O nových produktech, akčních nabídkách, radách podporujících zdraví a dlouhověkost?' /></div>
                <div><FormattedMessage description='App.Home.News.T3' defaultMessage='Zanechte nám svůj e-mail.' /></div>
                <div><FormattedMessage description='App.Home.News.T4' defaultMessage='Nebudeme vás zbytečně obtěžovat.' /></div>
            </div>
            <OrderFree setStatus={ setNews } packageId={ 7 } />
        </div> }
        { news === 'finished' && <div className='text-block'>
            <div><FormattedMessage description='App.Home.News.Success' defaultMessage='Thank you for being interested. We will take care of your e-mail as if it was a radish in our garden bed.' /><Radish size={ 20 } /></div>
        </div> }
    </View>
}
