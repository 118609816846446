import { useCallback } from 'react'

import { useForm } from './useForm'

export const useOnChange = ( controlId: string, type: 'value' | 'checked', onChange_?: ( ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> ) => void  ) => {
    const form = useForm()

    const onChange = useCallback( ( ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> ) => {
        form?.setData({ ...form.data, [ controlId ]: type === 'checked' ? (ev.currentTarget as HTMLInputElement).checked : ev.currentTarget.value }) 
        if( onChange_ ) onChange_( ev )
    }, [ form, controlId ] )

    return { form, onChange }
}