import styled from 'styled-components'

export const DivVW100 = styled.div({
    '@media(max-width: 788px)': {
        position: 'relative',
        width: '100vw',
        height: 'auto',
        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw'
    }
})