import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from './useAuth'
import { AuthContext, User } from '../types'

export const useRequireLogin = <T extends User>( require = true ): AuthContext<T> => {

    const { pathname } = useLocation()
    const navigate = useNavigate()
    const auth = useAuth<T>()

    useEffect( () => {
        if( require && !auth.isAuthenticated ) navigate( '/log-in', { replace: true, state: { from: pathname } })
    }, [ auth, require ] )

    return auth
}