import React from 'react'
import { useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { TwoColumns8x4 } from '../../layouts/TwoColumns8x4'
import { Radish } from '../../components/Radish'
import { MailTo } from '../../components/MailTo'

import { OrderResponse } from '../../../../../cz.vegilove.api/src/_api-models'

export const ConfirmOrder = () => {

    const state = useLocation().state as OrderResponse

    return <TwoColumns8x4
        heading={ <FormattedMessage description='app.cofirm-payment.page-title' defaultMessage='Order confirmation' /> }
        showLoading={ false }
    >
        <p><FormattedMessage description='app.cofirm-order.success' defaultMessage='Your order was registered{radish}' values={{ radish: <Radish size={ 20 } /> }} /></p>
        { state.totalAmount > 0 && <>
            <p><FormattedMessage description='App.ConfirmOrder.BankTransfer1' defaultMessage='Na váš e-mail jsme zaslali pokyny k platbě. Zpracování platby převodem z vašeho účtu nám zpravidla zabere 2-3dny. Mějte s námi tedy prosím trpělivost.' /></p>
            <p><FormattedMessage description='App.ConfirmOrder.BankTransfer2' defaultMessage='Pokud byste nakonec chtěli zaplatit on-line máte v mailu přiložen i link, kterým můžete kdykoliv iniciovat platbu přes platební bránu.' /></p>
        </> }
        { state.totalAmount == 0 && <>
            <p><FormattedMessage description='App.Home.MainCourse.RegFinished.ThankYou' defaultMessage='Děkujeme! Kurz putuje do vaší mailové schránky.' /></p>
            <p><FormattedMessage description='App.Home.MainCourse.RegFinished.SpamNotice' defaultMessage='Pokud kurz do pár minut nepřijde, mrkněte do spamu a nastavte moji adresu {email} jako přátelskou. Děkuji.' values={{ email: <MailTo /> }} /></p>
        </> }
        <div><FormattedMessage description='app.cofirm.Cheers' defaultMessage='Rostlinným sýrům Zdar!' /></div>
        <div><FormattedMessage description='app.cofirm.WithLove' defaultMessage='S láskou Míša' /></div>
    </TwoColumns8x4>
}