import React from 'react'
import { useIntl } from 'react-intl'

import radish from '../../../assets/images/logo/radish-narrow-212x400.png?format=webp'

type RadishProps = {
    size: number
    rotation?: number
    style?: React.CSSProperties
}

export const Radish = ( { size, rotation, style }: RadishProps ) => {

    const intl = useIntl()

    const radishAlt = intl.formatMessage({ description: 'App.Course.RadishIcon', defaultMessage: 'Radish' })

    return <img src={ radish.src } alt={ radishAlt } width={ size.toString() } style={ rotation ? { ...style, transform: `rotate(${ rotation?.toString() }deg)` } : { ...style } } />
}