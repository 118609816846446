import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Col, Row } from 'react-bootstrap'

import { ComponentHeading } from '../../components/ComponentHeading'
import { View } from '../../components/View'

import michaela from '../../../assets/images/michaela.jpg?format=webp'
import { MailTo } from '../../components/MailTo'
import { CONTENT_SOURCES } from '../../AppConfig'

export const Contact = () => {

    const intl = useIntl()

    return <View>
        <ComponentHeading>
            <FormattedMessage description='App.Contact.Title' defaultMessage='Contact' />
        </ComponentHeading>
        <Row className='text-block'>
            <Col sm={ 12 } md= { 5 }>
                <div className='mb-4'><img src={ michaela.src } alt='Michaela Levíčková' className='w-100' /></div>
            </Col>
            <Col sm={ 12 } md={ 7 }>
                <div>{ intl.formatMessage({ description: 'App.Contact.AuthorAndRepresentative', defaultMessage: 'Autorka kurzů a jednatelka společnosti' }) } Božsky jez s.r.o.</div>
                <div>
                    <div>Mgr. Michaela Levíčková Vlková</div>
                    <div>Slunečná 1753</div>
                    <div>26601</div> 
                    <div>Beroun 2</div>
                    { intl.locale === 'cs-CZ' && <div>{ intl.formatMessage({ description: 'App.Contact.CzechRepublic', defaultMessage: 'Czech Republic' })}</div> }
                    <div>&nbsp;</div>
                    <div>+420 605583469</div>
                    <div><MailTo /></div>
                    <div>{ intl.locale === 'cs-CZ' 
                        ? <a href='https://www.bozskyjezbozskyzij.cz'>www.bozskyjezbozskyzij.cz</a>
                        : <a href='https://www.vegilove.eu'>www.vegilove.eu</a>
                    }</div>
                </div>
                <div className='pt-5'>
                    <div>{ intl.formatMessage({ description: 'App.Contact.Company', defaultMessage: 'Company:' })}</div>
                    <div>Božsky jez s.r.o.</div>
                    <div>{ intl.formatMessage({ description: 'App.Contact.RegNo', defaultMessage: 'reg. no:' })} 14036274</div>
                    <div>{ intl.formatMessage({ description: 'App.Contact.RegAt', defaultMessage: 'registered at:' })} Městského soudu v Praze, { intl.formatMessage({ description: 'App.Contact.RegFileNo', defaultMessage: 'file no:' })}&nbsp;C&nbsp;359300</div>
                    <div>&nbsp;</div>
                    <div>{ intl.formatMessage({ description: 'App.Contact.BankContact', defaultMessage: 'bankovní spojení' })}</div>
                    { intl.locale === 'cs-CZ' && <div>{ intl.formatMessage({ description: 'App.Contact.BankAccountNo', defaultMessage: 'acct. no:' })} 123-5666190237/0100</div> }
                    <div>IBAN: CZ2301000001235666190237</div>
                    <div>BIC/SWIFT: KOMBCZPPXXX</div>
                </div>
                <div className='pt-5'>
                    <div>{ intl.formatMessage({ description: 'App.Contact.Documents', defaultMessage: 'Important documents:' })}</div>
                    <div><a href={ `${ CONTENT_SOURCES[ 'aws-cdn' ]}/oou-${ intl.locale }-202301.pdf` } download target='_blank' rel="noreferrer">{ intl.formatMessage({ description: 'App.Contact.PrivacyPolicy', defaultMessage: 'Privacy policy rules' }) }</a></div>
                    <div><a href={ `${ CONTENT_SOURCES[ 'aws-cdn' ]}/op-${ intl.locale }-202301.pdf` } download target='_blank' rel="noreferrer">{ intl.formatMessage({ description: 'App.Contact.PurchaseConditions', defaultMessage: 'Purchase conditions' }) }</a></div>
                </div>
            </Col>
        </Row>
    </View>
}