import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { AsyncState, useAsync } from '../../Common'
import { useNotifications } from '../../Notifications'

import { AuthOperResult, User } from '../types'
import { useAuth } from './useAuth'

type UsePwdChangeReturn<T extends User> = [
    ( pwd: string ) => void,
    AsyncState<AuthOperResult<T>>
]

export const usePwdChange = <T extends User>(): UsePwdChangeReturn<T> => {

    const intl = useIntl()

    const { addNotification, removeAllNotifications } = useNotifications()
    
    const auth = useAuth()

    const pwdChangeRequest = useAsync<AuthOperResult<User>>({
        onSuccess: ( state ) => {
            if( typeof state.data === 'undefined' || ! state.data.success || typeof state.data.status === 'undefined' || state.data?.status > 300 ) {
                addNotification({ variant: 'danger', category: 'auth', content: intl.formatMessage({ description: 'auth.pwd-change.error', defaultMessage: 'Password change did not succeeded. Try it once more.' }) })
            } else {
                addNotification({ variant: 'success', category: 'auth', content: intl.formatMessage({ description: 'auth.pwd-change.sucess', defaultMessage: 'Password has been changed. Please login with new password.' }), timeout: 5000 })
                auth.logout( true )
            }
        },
        onError: () => {
            addNotification({ variant: 'danger', category: 'auth', content: intl.formatMessage({ description: 'auth.pwd-change.error', defaultMessage: 'Password change did not succeeded. Try it once more.' }) })
        }
    })

    const run = useCallback( ( pwd: string ) => {
        if( auth.pwdChange ) {
            if( ! pwdChangeRequest.state.processing ) {
                removeAllNotifications( 'auth' )
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                pwdChangeRequest.run({ fn: () => auth.pwdChange!( pwd ) })    
            }
        } else throw new Error( 'Function pwdChange does not exits!' )
    }, [ auth.pwdChange, pwdChangeRequest, removeAllNotifications ] )
    
    return [ run, pwdChangeRequest.state as AsyncState<AuthOperResult<T>> ]
}