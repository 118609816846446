import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { ComponentWithChildrenProps, ComponentWithStyle } from '../../../Common'

import { View } from '../../components/View'
import { ComponentHeading } from '../../components/ComponentHeading'
import { Loading } from '../../components/Loading'
import { GoToHomeBtn } from '../../components/GoToHomeBtn'

type LayoutProps = ComponentWithChildrenProps & ComponentWithStyle & {
    heading: React.ReactNode
    showLoading?: boolean
}

export const TwoColumns8x4 = ( { heading, children, showLoading }: LayoutProps ) => {
    return <View>
        <ComponentHeading>{ heading }</ComponentHeading>
        <Loading show={ showLoading } />
        <Row>
            <Col md={ 8 }>{ children }</Col>
            <Col md={ 4 }><GoToHomeBtn /></Col>
        </Row>
    </View>
}