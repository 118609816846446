import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, ProgressBar, Ratio } from 'react-bootstrap'
import classNames from 'classnames'

import { ContentDefinition, isContentDefinitionWithLink } from '../../AppTypes'

type TileProps = {
    tile: ContentDefinition
    progress?: number
    favicon?: boolean
    size?: number
    className?: string
}

export const Tile = ( { tile, progress, size, className }: TileProps ) => {

    const tileJSX = <>
        <Ratio aspectRatio={2 / 3}>
            <div className='tile-cover d-flex align-items-center justify-content-center p-1 border border-brown'>
                <img className='w-100' src={ tile.imgUrl } alt={ tile.imgAlt }/>
                { /*<div className='tile-title'>{ tile.title }</div>*/ }
            </div>
        </Ratio>
        { typeof progress !== 'undefined' && <ProgressBar now={ progress || 0 } label={`${ progress || 0 }%`} visuallyHidden /> }
        { typeof tile.title === 'string' 
            ? <Button className='w-100 p-2 mt-2'>{ tile.title }</Button> 
            : tile.title
        }
    </>

    return <Col className={ classNames( className, 'tile' )} md={ size || 4 }>
        <div className='m-1 p-2 text-block' style={{ backgroundColor: '#fff' }}><>
            { /*favicon && <Favorite video={ tile.title } />*/ }
            { isContentDefinitionWithLink( tile )
                ? <Link to={ tile.to }>{ tileJSX }</Link>
                : { tileJSX }
            }
        </></div>
        
        { tile.additionalContent && <div className='m-1 p-2'>
            { tile.additionalContent }
        </div>}
    </Col>
}