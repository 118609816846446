import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Col, Ratio, Row } from 'react-bootstrap'

import { LinkButton, useMatchMedia } from '../../../../Common'

import { Radish } from '../../../components/Radish'

import mozarella from '../../../../assets/images/mozarella.jpg?format=webp'

export const CourseDetailsVideo = () => {

    const intl = useIntl()

    const orderUrl = intl.locale === 'cs-CZ' ? 'objednat' : 'order'
    
    const matches = useMatchMedia( '(min-width: 992px)' )

    return (<div>
        <Row className='border border border-light-brown g-0'>
            <Col sm={ 12 } className='p-4'>
                <div><FormattedMessage description='App.CourseDetailsVideo.VideoLength' defaultMessage='2 hodiny videa a 80 stran' /></div>
                <div className="pt-3"><FormattedMessage description='App.CourseDetailsVideo.17recepts' defaultMessage='17 videonávodů na rostlinné alternativy mléčných výrobků' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.PlusEbook' defaultMessage='+ celý e-book v ceně kurzu' /></div>
                {/* <div className="pt-3 text-decoration-underline"><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept' defaultMessage='u každého receptu:' /></div> */}
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.VideoManual' defaultMessage='podrobný video návod s detailním postupem výroby' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.PrintManual' defaultMessage='návod k vytištění' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Sources' defaultMessage='soupis surovin k tisku' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Tools' defaultMessage='seznam pomůcek k tisku' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Tips' defaultMessage='tipy, jak používat k tisku' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Storing' defaultMessage='způsob skladování k tisku' /></div>
            </Col>
            <Col sm={ 12 } className='bg-light-brown p-3 text-center'>
                <div className="text-uppercase"><FormattedMessage description='App.CourseDetailsVideo.Bonus' defaultMessage='a jako bonus vás čeká recept na ořechovou paštiku' /></div>
            </Col>
        </Row>
        <div className='image-block text-center'><Radish size={ 80 } rotation={ 280 } /></div>
        <Row className='text-block text-center bg-light-brown'>
            <Col><LinkButton to={ `/${ orderUrl }/5` } className='p-0 fs-5'>
                <div><FormattedMessage description='App.CourseDetailsVideo.CourseFromEbook' defaultMessage='kurz formou videokurzu' /></div>
                <div>{ intl.locale === 'cs-CZ' ? '2700 Kč' : '$119' }</div>
            </LinkButton></Col>
        </Row>
        <Ratio aspectRatio={ 4000 / 6000 } className='image-block'>
            <img src={ mozarella.src } alt={ intl.formatMessage({ description: 'App.CourseDetailsVideo.Mozarella', defaultMessage: 'Mozarella' }) } />
        </Ratio>
        <Row className='text-block'>
            <Col>
                <div className="py-3"><FormattedMessage description='App.CourseDetailsVideo.YouWillGetRecipesFor' defaultMessage='Získáte videonávod na veganský:' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.ProbioQuark' defaultMessage='probiotický tvaroh' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.CreamForCooking' defaultMessage='smetanu na vaření' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Parmegiano' defaultMessage='parmezán' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.CheeseForBaking' defaultMessage='tekutý sýr na zapékané pokrmy' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.SourCream' defaultMessage='zakysanou smetanu' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.FermentedCheese' defaultMessage='fermentovaný sýr' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Butter' defaultMessage='máslo' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Feta' defaultMessage='fetu' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.GoatCheese' defaultMessage='kozí sýr' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.PartyCheese' defaultMessage='párty sýr' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Ricotta' defaultMessage='ricottu' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Mozarella' defaultMessage='mozarellu' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Mayonese' defaultMessage='majonézu' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.WhiteYogurt' defaultMessage='bílý jogurt' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.ChocoYogurt' defaultMessage='čokoládový jogurt' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.SoftAgedCheese' defaultMessage='jemný zrající sýr' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.AgedChedar' defaultMessage='zrající čedar' /></div>
                <div className='pt-3'><FormattedMessage description='App.CourseDetailsVideo.PlusEbook' defaultMessage='+ celý e-book v ceně kurzu' /></div>
            </Col>
            <div className='free-image-block' style={ matches ? { top: '35%', left: '60%' } : { position: 'absolute', bottom: '30%', right: 0 } }><Radish size={ 100 } /></div>
        </Row>
    </div>)
}
