import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Col, Ratio, Row } from 'react-bootstrap'
import styled from 'styled-components'

import { LinkButton, useMatchMedia } from '../../../../Common'

import { Radish } from '../../../components/Radish'

import cheeseInGlass from '../../../../assets/images/cheese-in-glass.jpg?format=webp'

const RadishSmallWrapper = styled.div({
    position: 'absolute',
    bottom: '10%',
    left: '55%',
    width: 'auto'
})

const RadishBigWrapper = styled.div({
    position: 'absolute',
    bottom: '40%',
    left: '65%',
    width: 'auto'
})


export const CourseDetailsEbook = () => {

    const intl = useIntl()

    const orderUrl = intl.locale === 'cs-CZ' ? 'objednat' : 'order'
    
    return (<div>
        <Row className='border border border-light-brown g-0'>
            <Col sm={ 12 } className='p-4'>
                <div><FormattedMessage description='App.CourseDetailsEbook.BookLength' defaultMessage='80 stran' /></div>
                <div className="pt-3"><FormattedMessage description='App.CourseDetailsEbook.17recepts' defaultMessage='17 receptů na rostlinné alternativy mléčných výrobků' /></div>
                <div>&nbsp;</div>
                {/* <div className="pt-3 text-decoration-underline"><FormattedMessage description='App.CourseDetailsEbook.ForEachRecept' defaultMessage='u každého receptu:' /></div> */}
                <div><FormattedMessage description='App.CourseDetailsEbook.ForEachRecept.Foto' defaultMessage='velké fotografie finálních výrobků' /></div>
                <div><FormattedMessage description='App.CourseDetailsEbook.ForEachRecept.Steps' defaultMessage='podrobný postup výroby' /></div>
                <div><FormattedMessage description='App.CourseDetailsEbook.ForEachRecept.Sources' defaultMessage='soupis surovin' /></div>
                <div><FormattedMessage description='App.CourseDetailsEbook.ForEachRecept.Tools' defaultMessage='seznam pomůcek' /></div>
                <div><FormattedMessage description='App.CourseDetailsEbook.ForEachRecept.Tips' defaultMessage='tipy, jak používat' /></div>
                <div><FormattedMessage description='App.CourseDetailsEbook.ForEachRecept.Storing' defaultMessage='způsob skladování' /></div>
            </Col>
            <Col sm={ 12 } className='bg-light-brown p-3 text-center'>
                <div className="text-uppercase"><FormattedMessage description='App.CourseDetailsEbook.Bonus' defaultMessage='a jako bonus vás čeká recept na ořechovou paštiku' /></div>
            </Col>
        </Row>
        <div className='image-block text-center'><Radish size={ 80 } rotation={ 80 } /></div>
        <Row className='text-block text-center bg-light-brown'>
            <Col><LinkButton to={ `/${ orderUrl }/4` } className='p-0 fs-5'>
                <div><FormattedMessage description='App.CourseDetailsEbook.CourseFromEbook' defaultMessage='kurz formou e-booku' /></div>
                <div>{ intl.locale === 'cs-CZ' ? '430 Kč' : '$19' }</div>
            </LinkButton></Col>
        </Row>
        <Ratio aspectRatio={ 4000 / 6000 } className='image-block'>
            <img src={ cheeseInGlass.src } alt={ intl.formatMessage({ description: 'App.CourseDetailsEbook.FermentedCheese', defaultMessage: 'Fermented Cheese' }) } />
        </Ratio>
        <Row className='text-block'>
            <Col>
                <div className="py-3"><FormattedMessage description='App.CourseDetailsVideo.YouWillGetRecipesFor' defaultMessage='Získáte recept na veganský:' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.ProbioQuark' defaultMessage='probiotický tvaroh' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.CreamForCooking' defaultMessage='smetanu na vaření' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Parmegiano' defaultMessage='parmezán' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.CheeseForBaking' defaultMessage='tekutý sýr na zapékané pokrmy' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.SourCream' defaultMessage='zakysanou smetanu' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.FermentedCheese' defaultMessage='fermentovaný sýr' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Butter' defaultMessage='máslo' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Feta' defaultMessage='fetu' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.GoatCheese' defaultMessage='kozí sýr' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.PartyCheese' defaultMessage='párty sýr' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Ricotta' defaultMessage='ricottu' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Mozarella' defaultMessage='mozarellu' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.Mayonese' defaultMessage='majonézu' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.WhiteYogurt' defaultMessage='bílý jogurt' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.ChocoYogurt' defaultMessage='čokoládový jogurt' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.SoftAgedCheese' defaultMessage='jemný zrající sýr' /></div>
                <div><FormattedMessage description='App.CourseDetailsVideo.ForEachRecept.AgedChedar' defaultMessage='zrající čedar' /></div>
            </Col>
            <RadishSmallWrapper><Radish size={ 40 } rotation={ 170 } /></RadishSmallWrapper>
            <RadishBigWrapper><Radish size={ 80 } /></RadishBigWrapper>
        </Row>
    </div>)
}
