import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
//import { QueryClient, QueryClientProvider } from 'react-query'
import { Container, Row, Col } from 'react-bootstrap'

import { NotificationStack, NotificationOnPageLoad } from '../../../Notifications'
import { AuthContextProvider, AuthRoutes, AuthServiceAPI } from '../../../Auth'
import { ToTopBtn, ToTopOnRoute } from '../../../Common'

import { API_URL, BASENAME } from '../../AppConfig'
import { ROUTES } from '../../AppRoutes'
import { User } from '../../AppTypes'

import { AppHeader } from '../AppHeader'
import { AppFooter } from '../AppFooter'
import { ComponentHeading } from '../ComponentHeading'
import { ComponentStatus } from '../ComponentStatus'
import { View } from '../View'
import { Home } from '../../views/Home'
import { AppContext } from '../AppContext'

//const queryClient = new QueryClient()

const authService = AuthServiceAPI<User>( API_URL )

export const AppContent = () => {
    return <>
        <NotificationOnPageLoad />
        <AuthContextProvider 
            authService={ authService } 
            pageHeading={ ComponentHeading } 
            pageStatus={ ComponentStatus } 
            viewWrapper={ View }
            loginHome='/MyContent'
        >
            <Container>
                <ToTopOnRoute />
                <ToTopBtn variant='light' />
                <AppHeader />
                <Row><Col className='p-0'>
                    <AuthRoutes routes={ ROUTES } defaultRoute={{ element: <Home />, isAuthenticated: false }} />
                </Col></Row>
                <AppFooter />
                <NotificationStack />
            </Container>
        </AuthContextProvider>
    </>
}

export const App = () => <AppContext><Router basename={ BASENAME }><AppContent /></Router></AppContext>