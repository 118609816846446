import React, { useEffect } from 'react'
import { defineMessage, FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'

import { JwtDecodedToken, parseJwt, useAuth } from '../../../Auth'
import { useFetch, useQueryString } from '../../../Common'
import { useNotifications } from '../../../Notifications'

import { ComponentHeading } from '../../components/ComponentHeading'
import { ComponentStatus } from '../../components/ComponentStatus'
import { GoToHomeBtn } from '../../components/GoToHomeBtn'
import { API_URL } from '../../AppConfig'
import { View } from '../../components/View'
import { TryOnceMoreMsg } from '../../AppConsts'
import { MailTo } from '../../components/MailTo'

const CanNotUnsubsMsg = defineMessage({ description: 'App.Unsubscribe.CanNot', defaultMessage: 'We can not unsubscribe you from this mailing list.' })

export const Unsubscribe = () => {

    const intl = useIntl()

    const { id } = useParams()
    
    const token = useQueryString( 'token' )
    const tokenDecoded = token ? parseJwt( token ) as JwtDecodedToken & { user: { status: 'REGP' | 'REGF' | 'UNREGISTERED' } } : undefined

    const { state: unsubscribeRequestState } = useFetch({
        url: `${ API_URL }/subscription/cancel/${ id ?? '' }?token=${ token ?? '' }`,
        // if not token, do not start verification
        noAutoRun: !tokenDecoded
    }) 

    return <View>
        <ComponentHeading>
            <FormattedMessage description='App.Unsubscribe.PageTitle' defaultMessage='Unsubscribe' />
        </ComponentHeading>
        <ComponentStatus 
            loading={ unsubscribeRequestState.processing } 
            error={ unsubscribeRequestState.success ? undefined: unsubscribeRequestState.message } 
        />
        <Row>
            <Col md={ 8 }>
                { !token && intl.formatMessage( CanNotUnsubsMsg ) && <FormattedMessage description='App.Unsubscribe.InvalidRequest' defaultMessage='Your request has invalid format.' /> && intl.formatMessage( TryOnceMoreMsg, { email: <MailTo /> } ) }
                { unsubscribeRequestState.success && <FormattedMessage description='App.Unsubscribe.Success' defaultMessage='Your email was sucessfully unsubscribed from this mailing list. Thank you for using our services.' /> }
                { unsubscribeRequestState.phase === 'error' && intl.formatMessage( CanNotUnsubsMsg ) && <FormattedMessage description='App.Unsubscribe.UnknownError' defaultMessage='Unknown error while processing your request.' /> && intl.formatMessage( TryOnceMoreMsg, { email: <MailTo /> } ) }
            </Col>
            <Col md={ 4 } className='text-end'>
                <GoToHomeBtn />
            </Col>
        </Row>
    </View>
}