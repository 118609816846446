import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'

import { JwtDecodedToken, parseJwt, useAuth } from '../../../Auth'
import { useFetch, useQueryString } from '../../../Common'
import { useNotifications } from '../../../Notifications'

import { ComponentHeading } from '../../components/ComponentHeading'
import { ComponentStatus } from '../../components/ComponentStatus'
import { GoToHomeBtn } from '../../components/GoToHomeBtn'
import { API_URL } from '../../AppConfig'

export const Unregister = () => {

    const intl = useIntl()
    
    const navigate = useNavigate()

    const auth = useAuth()

    const { addNotification } = useNotifications()

    const token = useQueryString( 'token' )
    const tokenDecoded = token ? parseJwt( token ) as JwtDecodedToken & { user: { status: 'REGP' | 'REGF' | 'UNREGISTERED' } } : undefined

    // if no token notify and go home
    useEffect( () => {
        if( !token ) {
            addNotification({ variant: 'danger', category: 'auth', content: intl.formatMessage({ description: 'app.unregister.no-token', defaultMessage: 'We can not unregister you. No token was provided.' }), timeout: 3000 })
            navigate( '/', { replace: true } )
        }
        if( tokenDecoded && tokenDecoded.user.status === 'REGF' ) {
            if( auth.isAuthenticated )
                addNotification({ variant: 'warning', category: 'auth', content: intl.formatMessage({ description: 'app.unregister.gotoProfile', defaultMessage: 'To unregister - click on "DELETE MY PROFILE".' }), timeout: 8000 })
            else
                addNotification({ variant: 'warning', category: 'auth', content: intl.formatMessage({ description: 'app.unregister.gotoLogin', defaultMessage: 'To unregister, you must login, go to your profile and click "DELETE MY PROFILE".' }), timeout: 8000 })
            navigate( '/profil', { replace: true } )
        }
    }, [ token ] )

    const { state: unregisterRequestState } = useFetch({
        url: `${ API_URL }/user/unregister/partial?token=${ token || '' }`,
        // if not token, do not start verification or it is not REGP
        noAutoRun: !tokenDecoded || tokenDecoded.user.status !== 'REGP'
    }) 

    return <>
        <ComponentHeading>
            <FormattedMessage description='auth.unregister.page-title' defaultMessage='Cancel your registration' />
        </ComponentHeading>
        <ComponentStatus 
            loading={ unregisterRequestState.processing } 
            error={ unregisterRequestState.success ? undefined: unregisterRequestState.message } 
        />
        { unregisterRequestState.success && <Row>
            <Col md={ 8 }>
                <FormattedMessage description='app.unregister.success' defaultMessage='We are sorry that you are leaving us! Your email was sucessfully unregistered. Thank you for using our services.' />
            </Col>
            <Col md={ 4 } className='text-end'>
                <GoToHomeBtn />
            </Col>
        </Row> }
    </>
}