import React from 'react'

import { Lang } from '../I18N'

import English from '../../translation/compiled/en-US.json'
import Czech from '../../translation/compiled/cs-CZ.json'
import { FormattedMessage } from 'react-intl'
import { CookiePurposeType } from '../Common/components/CookieConsent'
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager'

import { ContentSources } from '../../../cz.vegilove.api/src/_api-models'

import logo_cs from '../assets/images/logo/logo_cs_crop.png'
import logo_en from '../assets/images/logo/logo_en_crop.png'

export const BASENAME = '/'

export const GTM_PARAMS: ISnippetsParams = {
    id: 'GTM-NG2TSJ9',
    ...ENV_GTM_PARAMS
}

export const WEB_URL = 'https://www.vegilove.cz'
export const WEB_SITE_NAME = 'Vegilove.cz'
export const WEB_LOGO = [
    { isoCode: 'cs-CZ', logo: logo_cs.src },
    { isoCode: 'en-US', logo: logo_en.src }
]

export const GRAPHQL_URL = 'https://api.vegilove.cz/'
export const API_URL = ENV_API_URL

const en_svg = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" style={{ marginTop: '0.25em' }}><clipPath id="a"><path d="M0 0v30h60V0z"/></clipPath><clipPath id="b"><path d="M30 15h30v15zv15H0zH0V0zV0h30z"/></clipPath><g clipPath="url(#a)"><path d="M0 0v30h60V0z" fill="#012169"/><path d="M0 0l60 30m0-30L0 30" stroke="#fff" strokeWidth="6"/><path d="M0 0l60 30m0-30L0 30" clipPath="url(#b)" stroke="#C8102E" strokeWidth="4"/><path d="M30 0v30M0 15h60" stroke="#fff" strokeWidth="10"/><path d="M30 0v30M0 15h60" stroke="#C8102E" strokeWidth="6"/></g></svg>
const cz_svg = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 600"><rect width="900" height="600" fill="#d7141a"/><rect width="900" height="300" fill="#fff"/><path d="M 450,300 0,0 V 600 z" fill="#11457e"/></svg>
const langStyles = { display: 'inline-block', width: '1.2em', height: '1em', marginLeft: '.2em', marginTop: '-0.1em', verticalAlign: 'text-top' }

export const LANG_LIST: Lang[] = [
    {
        isoCode: 'en-US',
        label: <span style={ langStyles }>{ en_svg }</span>, //'EN',
        title: <FormattedMessage description='App.Lang.English' defaultMessage='English' />,
        messages: English
    },
    {
        isoCode: 'cs-CZ',
        label: <span style={ langStyles }>{ cz_svg }</span>, //'CS'
        title: <FormattedMessage description='App.Lang.Czech' defaultMessage='Czech' />,
        messages: Czech
    },
]

export const CONTENT_SOURCES: { [ key in ContentSources ]: string } = {
    'aws-cdn': 'https://cdn.vegilove.cz',
    'aws-private': 'https://private.vegilove.cz',
    'web': ''
}

export const PAY_PAL_CLIENT_ID = 'ATXJiWYtDjKTQHaiHKUHVdcXgEAQN-uLZCwc833UYaLRomfULa9Nr-K974zYUKb7WGiKkaUv7Qy_6g6A'

export const CookieConsentSupportedTypes: CookiePurposeType[] = [ 'necessary', 'preferences', 'statistics' ]
export const CookieConsentDefaultConsent = { necessary: true, preferences: false, statistics: false }