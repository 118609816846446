import React from 'react'
import classNames from 'classnames'

interface PageComponentHeadingProps {
    children?: React.ReactNode
    className?: string
    style?: React.CSSProperties
}

export const ComponentHeading = ( { children, className, style }: PageComponentHeadingProps ) => {
    const cls = React.useMemo( () => classNames( 'page-heading', className ), [ className ] )
    return <h2 className={ cls } style={ style }>{ children }</h2>
}
