import React from 'react'
import { Radish } from '../Radish'

const animationContainer: React.CSSProperties = {
    width: '102%',
    height: '102%',
    position: 'absolute',
    display: 'flex',
    margin: '-1%',
    background: '#99776255'
}

const spinner: React.CSSProperties = {
    margin: 'auto',
    animation: 'rotation 4s linear infinite',
}

type LoadingType = {
    show?: boolean
}

export const Loading = ( { show }: LoadingType ) => {

    return ( typeof show === 'undefined' || show ) ? <div style={ animationContainer }>
        <Radish size={ 30 } style={ spinner } />
    </div> : <></>
}