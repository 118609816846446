import React from 'react'
import { Alert } from 'react-bootstrap'

import { Loading } from '../Loading'

type AppStatusProps = {
    error?: string | null
    loading?: boolean
}

export const ComponentStatus = ({ error, loading }: AppStatusProps) => {
    return(<>
        { error && <Alert variant='danger'><strong>Chyba! </strong>{ error }</Alert> }
        { loading && <Loading /> }
    </>)
}