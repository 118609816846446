import React, { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from 'react-bootstrap'

import { useFetch, useQueryString } from '../../../Common'
import { useNotifications } from '../../../Notifications'
import { useAuth } from '../../../Auth'

import { TwoColumns8x4 } from '../../layouts/TwoColumns8x4'
import { Radish } from '../../components/Radish'
import { MailTo } from '../../components/MailTo'

import { API_URL } from '../../AppConfig'
import { OrderResponse } from '../../../../../cz.vegilove.api/src/_api-models'

export const ConfirmPayment = () => {

    const navigate = useNavigate()

    const intl = useIntl()
    
    const auth = useAuth()

    const { addNotification } = useNotifications()
    
    const { invoiceNo } = useParams()

    const error = useQueryString( 'error' )
    const emailLink = useQueryString( 'emailLink' )

    const { state: createPaymentRequestState, run: createPaymentRequestRun }= useFetch<{ newPaymentMethod: string }, OrderResponse>({
        url: API_URL + `/payment/createPayment/${ invoiceNo || '' }`,
        noAutoRun: true,
        method: 'POST',
        data: { newPaymentMethod: 'PAYU' },
        onSuccess: ( state ) => {
            if( state.data?.paymentUri ) window.location.href = state.data.paymentUri
        }
    }, [ invoiceNo ] ) // do not start automatically

    const gotoPaymentHandler = useCallback( () => {
        createPaymentRequestRun({})
    }, [] )

    // if no token notify and go home
    useEffect( () => {
        if( !invoiceNo ) {
            addNotification({ variant: 'danger', category: 'auth', content: intl.formatMessage({ description: 'app.cofirm-payment.no-invoiceId', defaultMessage: 'Something went wrong and we could not verify your payment.' }), timeout: 2000 })
            navigate( '/', { replace: true } )
        }
    } )

    return <TwoColumns8x4
        heading={ <FormattedMessage description='app.cofirm-payment.page-title' defaultMessage='Payment confirmation' /> }
        showLoading={ createPaymentRequestState.processing }
    >
        <>
            { /* Click from email */ emailLink && createPaymentRequestState.phase === 'init' && <>
                <p><FormattedMessage description='app.cofirm-payment.emailLink' defaultMessage='You can initiate payment gateway for your order no. {invoiceNo} by clicking {paymentButton}.' values={{ invoiceNo, paymentButton: <Button onClick={ gotoPaymentHandler }><FormattedMessage description='app.cofirm-payment.LinkToPGText' defaultMessage='I want to pay' /></Button> }} /></p>                
            </> }
            { /* Return from PGW with Error */ !emailLink && error === '501' && createPaymentRequestState.phase === 'init' && <>
                <p><FormattedMessage description='app.cofirm-payment.pgfailure.1' defaultMessage='Your order could not be paid.' /></p>
                <p><FormattedMessage description='app.cofirm-payment.pgfailure.2' defaultMessage='We are sorry. Your payment was not authorized or payment was cancelled.' /></p>
                <p><FormattedMessage description='app.cofirm-payment.pgfailure.3' defaultMessage='You can go back to payment gateway by clicking {paymentButton}. Or...' values={{ paymentButton: <Button onClick={ gotoPaymentHandler }><FormattedMessage description='app.cofirm-payment.LinkToPGText' defaultMessage='I want to pay' /></Button> }} /></p>
                <p><FormattedMessage description='app.cofirm-payment.pgfailure.4' defaultMessage='You can try it later. You will find your link to payment gateway in your email.' /></p>
            </> }
            { /* Return from PGW with OK OR new payment but already paid */ 
                (
                    ( !emailLink && error !== '501' && createPaymentRequestState.phase === 'init' ) 
                    || ( !createPaymentRequestState.success && createPaymentRequestState.errorCode === 'Payment.CreatePayment.AlreadyPaid' )
                ) && <>
                    <p><FormattedMessage description='app.cofirm-payment.success' defaultMessage='Your order was succesfully paid{radish}' values={{ radish: <Radish size={ 20 } /> }}/></p>
                    <p><FormattedMessage description='App.Home.MainCourse.RegFinished.ThankYou' defaultMessage='Děkujeme! Kurz putuje do vaší mailové schránky.' /></p>
                    <p><FormattedMessage description='App.Home.MainCourse.RegFinished.SpamNotice' defaultMessage='Pokud kurz do pár minut nepřijde, mrkněte do spamu a nastavte moji adresu {email} jako přátelskou. Děkuji.' values={{ email: <MailTo /> }} /></p>
                </> 
            }
            { /* When  */ createPaymentRequestState.phase !== 'init' && !createPaymentRequestState.success && createPaymentRequestState.errorCode !== 'Payment.CreatePayment.AlreadyPaid' &&  <>
                <div><FormattedMessage description='app.cofirm-payment.createPaymentFailure.1' defaultMessage='We are very sorry, your payment request could not be processed.' /></div>
                <div><FormattedMessage description='app.cofirm-payment.createPaymentFailure.2' defaultMessage='Please, contact us via {email}. Or try it later.' values={{ email: <MailTo /> }} /></div>
            </> }
        </>
        <div><FormattedMessage description='app.cofirm.Cheers' defaultMessage='Rostlinným sýrům Zdar!' /></div>
        <div><FormattedMessage description='app.cofirm.WithLove' defaultMessage='S láskou Míša' /></div>
    </TwoColumns8x4>
}