import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Col, Ratio, Row } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'


import { useLogo } from '../../hooks/useLogo'
import { Tile } from '../../components/Tile'
import { OrderFree } from '../../components/OrderFree'
import { Radish } from '../../components/Radish'
import { MailTo } from '../../components/MailTo'
import { View } from '../../components/View'

import { CourseDetailsEbook } from './CourseDetailsEbook'
import { CourseDetailsVideo } from './CourseDetailsVideo'

import ebook from '../../../assets/images/ebook-tile.png?format=webp'
import video from '../../../assets/images/video-tile.png?format=webp'
import partyCheese from '../../../assets/images/party-cheese.jpg?format=webp'
import cow from '../../../assets/images/cow.png?format=webp'

import 'animate.css'

export const CourseSelection = () => {

    const intl = useIntl()

    const orderUrl = intl.locale === 'cs-CZ' ? 'objednat' : 'order'

    const logo = useLogo()

    // used for CSS Transition
    const nodeRef = React.useRef( null )
    const freeInputRef = React.useRef<HTMLInputElement>( null )

    const [ registration, setRegistration ] = useState<'hidden' | 'visible' | 'finished'>( 'hidden' )
    const [ samplePack, setSamplePack ] = useState<number | undefined>( undefined )

    return (<View>
        <Row>
            <Tile size={ 6 } className='p-0' tile={{ 
                key: 'ebook', 
                title: intl.formatMessage({ description: 'App.CourseSelection.EbookTitle', defaultMessage: 'Chci e-book' }), 
                imgUrl: ebook.src, to: `/${ orderUrl }/4`,
                imgAlt: intl.formatMessage({ description: 'App.CourseSelection.EbookAlt', defaultMessage: 'Tablet s pdf e-bookem' }), 
                additionalContent: <>
                    <div className='text-center sweet-belly fs-3 pb-2'>{ intl.formatMessage({ description: 'App.CourseSelection.OrVoucher', defaultMessage: '~nebo dárkový poukaz' }) }</div>
                    <CourseDetailsEbook />
                </>
            }} />
            <Tile size={ 6 } className='p-0' tile={{ 
                key: 'video', 
                title: intl.formatMessage({ description: 'App.CourseSelection.VideoTitle', defaultMessage: 'Chci videokurz' }), 
                imgUrl: video.src, to: `/${ orderUrl }/5`,
                imgAlt: intl.formatMessage({ description: 'App.CourseSelection.VideoAlt', defaultMessage: 'Notebook s videokurzem' }), 
                additionalContent: <>
                    <div className='text-center sweet-belly fs-3 pb-2'>{ intl.formatMessage({ description: 'App.CourseSelection.OrVoucher', defaultMessage: '~nebo dárkový poukaz' }) }</div>
                    <CourseDetailsVideo />
                </>
            }} />
        </Row>
        <div className='text-center'>
            { registration !== 'finished' && <>
                <Row className='text-block border border-5 border-light-brown'>
                    <Col>
                        <h3><FormattedMessage description='App.CourseSelection.StillThinking' defaultMessage='Ještě váháte?' /></h3>
                        <h3><FormattedMessage description='App.CourseSelection.AskFreeExample' defaultMessage='Řekněte si o ukázku zdarma' /></h3>
                        <div className='mt-3'><Button variant='light-brown' onClick={ () => { setSamplePack( 2 ); setRegistration( 'visible' ) } }><FormattedMessage description='App.CourseSelection.IWantFreeExampleEbook' defaultMessage='Prosím si ukázku e-booku' /></Button></div>
                        <div className='mt-1'><Button variant='light-brown' onClick={ () => { setSamplePack( 3 ); setRegistration( 'visible' ) } }><FormattedMessage description='App.CourseSelection.IWantFreeExampleOnline' defaultMessage='Prosím si ukázku videokurzu' /></Button></div>
                    </Col>
                </Row>
                <CSSTransition in={ registration === 'visible' } nodeRef={ nodeRef } timeout={ 500 } appear unmountOnExit 
                    classNames={{
                        enterActive: 'animate__bounceIn',
                        exitActive: 'animate__bounceOut'
                    }}
                    onEntered={ () => { if( freeInputRef.current && freeInputRef.current.focus ) freeInputRef.current.focus() } }
                >
                    <div ref={ nodeRef } className='text-block text-start p-3'><OrderFree 
                        ref={ freeInputRef }
                        setStatus={ setRegistration }
                        packageId={ samplePack }
                        registerBtnSuffix={ samplePack === 2 ?<FormattedMessage description='App.CourseSelection.RegisterForEbookSample' defaultMessage='for E-book' /> : <FormattedMessage description='App.CourseSelection.RegisterForOnlineCourseSample' defaultMessage='for online course' /> }
                    /></div>
                </CSSTransition>
            </> }
            { registration === 'finished' && <div className="text-block">
                <div><FormattedMessage description='App.Home.MainCourse.RegFinished.ThankYou' defaultMessage='Děkujeme! Kurz putuje do vaší mailové schránky.' /></div>
                <div className='image-block'><Radish size={ 100 } rotation={ 90 } /></div>
                <div><FormattedMessage description='App.Home.MainCourse.RegFinished.SpamNotice' defaultMessage='Pokud kurz do pár minut nepřijde, mrkněte do spamu a nastavte moji adresu {email} jako přátelskou. Děkuji.' values={{ email: <MailTo /> }} /></div>
            </div> }
            <div className='image-block'><Radish size={ 100 } rotation={ 270 } /></div>      
            <Ratio aspectRatio={ 4000 / 6000 } className='image-block'>
                <img src={ partyCheese.src } alt={ intl.formatMessage({ description: 'App.CourseSelection.PartyCheese', defaultMessage: 'Party Cheese' }) } />
            </Ratio>
            <Row className='text-block'>
                <Col>
                    <div>
                        <FormattedMessage description='App.CourseSelection.FinalText' defaultMessage='Jezte zdravě a lahodně. Nedělejte kompromisy. Vyrábějte si výživově hodnotné a chuťově luxusní máslo, sýry, smetany a jogurty bez mléka, jen z kešu ořechů a pár dalších surovin. Kupované veganské sýry jsou nezdravé, plné škrobu, tuku a barviv. S tímto kurzem si plně vystačíte a budete mít stále co jíst.' />
                    </div>
                </Col>
            </Row>
            <Row>
                <Tile size={ 6 } tile={{ 
                    key: 'ebook', 
                    title: intl.formatMessage({ description: 'App.CourseSelection.EbookTitle', defaultMessage: 'Chci e-book' }), 
                    imgUrl: ebook.src, to: `/${ orderUrl }/4`, 
                    imgAlt: intl.formatMessage({ description: 'App.CourseSelection.EbookAlt', defaultMessage: 'Tablet s pdf e-bookem' }) 
                }} />
                <Tile size={ 6 } tile={{ 
                    key: 'video', 
                    title: intl.formatMessage({ description: 'App.CourseSelection.VideoTitle', defaultMessage: 'Chci videokurz' }), 
                    imgUrl: video.src, to: `/${ orderUrl }/5`,
                    imgAlt: intl.formatMessage({ description: 'App.CourseSelection.VideoAlt', defaultMessage: 'Notebook s videokurzem' })
                }} />
            </Row>
            <Row className='text-block'>
                <Col>
                    <div className='sweet-belly fs-1'><FormattedMessage description='App.CourseSelection.CheesesHura' defaultMessage='Domácím ořechovým sýrům Zdar' />&nbsp;<Radish size={ 25 } style={{verticalAlign: 'baseline', marginBottom: '-4px', display: 'inline-block' }} /></div>
                </Col>
            </Row>
            <Ratio aspectRatio={ 2351 / 3535 } className='image-block'>
                <img src={ cow.src } alt={ intl.formatMessage({ description: 'App.Course.Cow', defaultMessage: 'Cow' }) } />
            </Ratio>
            <Row>
                <Col>
                    <div className='image-block'><img src={ logo.src } alt='Logo Vegilove.cz' width='300' /></div>
                </Col>
            </Row>
        </div>
    </View>)
}
