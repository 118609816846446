import React, { forwardRef, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Col, Row } from 'react-bootstrap'

import { AsyncHttpRequestState, useChange } from '../../../Common'
import { useNotifications } from '../../../Notifications'
import { useAuth } from '../../../Auth'
import { Form, Text, FormContextType, CheckBox } from '../../../Forms'

import { API_URL, CONTENT_SOURCES } from '../../AppConfig'
import { User } from '../../AppTypes'
import { Loading } from '../Loading'

type OrderFreeProps = {
    packageId?: number
    setStatus?: ( registration: 'hidden' | 'visible' | 'finished' ) => void
    registerBtnSuffix?: string | React.ReactNode
}

export const OrderFree = forwardRef<HTMLInputElement, OrderFreeProps>( ( { packageId, setStatus, registerBtnSuffix }, ref ) => {

    const intl = useIntl()

    const auth = useAuth<User>()
    const user = auth.getUser()
    
    const { addNotification, removeAllNotifications } = useNotifications()

    const onSuccess = useCallback( ( state: AsyncHttpRequestState<unknown, unknown> ) => {
        if( state.success ) {
            if( setStatus ) setStatus( 'finished' )
            addNotification({ variant: 'success', category: 'app', content: intl.formatMessage({ description: 'app.newsletter.success', defaultMessage: 'Your new newsletter is on the way to you!!!' }), timeout: 6000 })
        } else {
            addNotification({ variant: 'danger', category: 'app', content: intl.formatMessage( { description: 'app.newsletter.error', defaultMessage: 'Registration did not succeeded. Try it once more.' } ) })
        }    
    }, [ setStatus, addNotification, intl ] )

    const onError = useCallback( ( state: AsyncHttpRequestState<unknown, unknown> ) => {
        addNotification({ variant: 'danger', category: 'app', content: state.message || intl.formatMessage( { description: 'app.newsletter.error', defaultMessage: 'Registration did not succeeded. Try it once more.' } ) })
    }, [ addNotification, intl ] )

    const orderRequest = useChange({ 
        url: API_URL + ( packageId ? '/order/new/register' : '/user/register' ), 
        method: 'POST',
        label: 'orderFree',
        onSuccess,
        onError
    })

    const onFinish = useCallback( ( form: FormContextType ) => {
        removeAllNotifications( 'auth ')
        const user = form.data
        orderRequest.run({ data: { 
            ...user,
            referrer: document.referrer,
            currency: 'CZK', 
            orderDetails: [ { packageId } ],
            agreements: [
                `oou-${ intl.locale }-202301.pdf`
            ]
        } })
    }, [ packageId, orderRequest, removeAllNotifications, intl ] )

    return(<div className='position-relative'>
        <Loading show={ orderRequest.state.processing } />
        <Form 
            formName='register'
            initialData={ 
                { name: user?.name || '', email: user?.email || '', status: 'REGP', agreement: false }
            }
            onFinishCallback={ onFinish }
        >
            <Text 
                ref={ ref }
                controlId='name' 
                className='mb-3'
                placeholder={ intl.formatMessage({ description: 'auth.name', defaultMessage: 'Name' }) }
                autocomplete
                required
                invalidMsg={ intl.formatMessage({ description: 'auth.name-required', defaultMessage: 'Please input your name!' }) }
            />
            <Text 
                controlId='email' 
                type='email'
                className='mb-3'
                placeholder={ intl.formatMessage({ description: 'auth.email', defaultMessage: 'Email' }) }
                disabled={ auth.isAuthenticated }
                autocomplete
                required
                invalidMsg={ intl.formatMessage({ description: 'auth.email-required', defaultMessage: 'Please input your Email!' }) }
            />
            <Row className='mb-3 text-start'>
                <Col>
                    <CheckBox 
                        controlId='agreement'
                        type='checkbox' 
                        required
                    >
                        <a href={ `${ CONTENT_SOURCES[ 'aws-cdn' ]}/oou-${ intl.locale }-202301.pdf` } download target='_blank' rel="noreferrer">{ intl.formatMessage({ description: 'auth.register.GDPR', defaultMessage: 'I agree with privacy policy of this web' }) }</a> 
                    </CheckBox>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col className='text-center'>
                    <Button type='submit' className='register-form-button'>
                        { packageId
                            ? <><FormattedMessage description='OrderFree.RegisterBtn' defaultMessage='Register' /> { registerBtnSuffix || '' }</>
                            : <FormattedMessage description='OrderFree.IWantNews' defaultMessage='I want the news' />
                        }
                    </Button>
                </Col>
            </Row>
        </Form>
    </div>)
} )