import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'

import { useFetch, useQueryString } from '../../../Common'
import { useNotifications } from '../../../Notifications'
import { useAuth } from '../../../Auth'

import { TwoColumns8x4 } from '../../layouts/TwoColumns8x4'
import { MailTo } from '../../components/MailTo'

import { API_URL } from '../../AppConfig'

export const ConfirmEmail = () => {

    const navigate = useNavigate()

    const intl = useIntl()
    
    const auth = useAuth()

    const { addNotification } = useNotifications()
    
    const token = useQueryString( 'token' )

    // if no token notify and go home
    useEffect( () => {
        if( !token ) {
            addNotification({ variant: 'danger', category: 'auth', content: intl.formatMessage({ description: 'app.cofirm-email.no-token', defaultMessage: 'We can not verify your email. No token was provided.' }), timeout: 2000 })
            navigate( '/', { replace: true } )
        }
    }, [ token ] )

    const { state: confirmRequestState } = useFetch<never, never>({
        url: `${ API_URL }/user/confirmEmail?token=${ token || '' }`,
        noAutoRun: !token,
        timeout: 3000
    } )

    return <TwoColumns8x4 
        heading={ <FormattedMessage description='app.cofirm-email.page-title' defaultMessage='Email confirmation' /> } 
        showLoading={ confirmRequestState.processing }
    >
        { confirmRequestState.success && <p>
            <FormattedMessage description='app.cofirm-email.success' defaultMessage='Thank you! Your email was sucessfully confirmed.' />
        </p> }
        { !confirmRequestState.success && <p>
            <FormattedMessage description='app.cofirm-email.success' defaultMessage='We are sorry! We could not confirm your email. Please, try it once more later or contact us via {email}.' values={{ email: <MailTo /> }} />
        </p> }
    </TwoColumns8x4>
}