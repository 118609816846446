export type DownloadProgress = {
    loaded: number
    total: number
    percent: number
}

export const download = ( url: string, filename: string, onProgress: ( progress: DownloadProgress ) => void, onFinish?: ( status: 'success' | 'abort' | 'error' ) => void ): () => void => {

    const xmlHTTP = new XMLHttpRequest()
    let blobResult: Blob
 
    xmlHTTP.responseType = 'arraybuffer'

    xmlHTTP.onload = function () { 
        blobResult = new Blob([ this.response ]) 
        if( onFinish ) onFinish( 'success' )
    }

    xmlHTTP.onabort = () => { if( onFinish ) onFinish( 'abort' ) }
    xmlHTTP.onerror = () => { if( onFinish ) onFinish( 'error' ) }

    xmlHTTP.onprogress = ( pr ) => {
        if( pr.lengthComputable ) onProgress( { loaded: pr.loaded, total: pr.total, percent: pr.loaded / pr.total } )
    }

    xmlHTTP.onloadend = () => {
        const url = window.URL.createObjectURL( blobResult )
        const tempEl = document.createElement( 'a' )
        document.body.appendChild( tempEl )
        tempEl.className = 'd-none'
        tempEl.href = url
        tempEl.download = filename
        tempEl.click()
        window.URL.revokeObjectURL(url)
    }    

    xmlHTTP.open( 'GET', url, true )

    xmlHTTP.send()

    return xmlHTTP.abort
}