import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import { BsFacebook, BsInstagram, BsYoutube } from 'react-icons/bs'

import { CookieDetail, CookieIntlMessages } from '../../../Common'

import { CookieConsentSupportedTypes } from '../../AppConfig'
import { MailTo } from '../MailTo'

export const AppFooter = () => {

    const intl = useIntl()

    const [ showCookieDetail, setShowCookieDetail ] = useState( false )
    const hideCookieDetail = () => setShowCookieDetail( false )

    return <>
        <CookieDetail supportedCookieTypes={ CookieConsentSupportedTypes } show={ showCookieDetail } hide={ hideCookieDetail } />
        <Row className='mt-5 mb-2 text-white bg-brown'>
            <Col className='mx-5 my-4'>
                <h3><FormattedMessage description='App.Footer.Contact.Heading' defaultMessage='Contact' /></h3>
                <div>Mgr.&nbsp;Michaela&nbsp;Levíčková&nbsp;Vlková</div>
                <div><MailTo /></div>
                <div>+420&nbsp;605&nbsp;583&nbsp;469</div>
                <div>Božsky&nbsp;jez&nbsp;s.r.o.</div>
            </Col>
            <Col className='mx-5 my-4'>
                <h3 className='d-none d-md-block'>&nbsp;</h3>
                <div>{ intl.locale === 'cs-CZ' 
                    ? <a target='_blank' rel='noreferrer' href='https://www.bozskyjezbozskyzij.cz' className='link-light'>www.bozskyjezbozskyzij.cz</a>
                    : <a href='https://www.vegilove.eu' className='link-light'>www.vegilove.eu</a>
                }</div>
                <div className='fs-5 app-footer-social-buttons'>
                    <a target='_blank' rel='noreferrer' href={ intl.locale === 'cs-CZ' ? 'https://www.facebook.com/bozskyjezbozskyzij' : 'https://www.facebook.com/vegilovecz' } className='link-light'><BsFacebook /></a>
                    <a target='_blank' rel='noreferrer' href='http://www.instagram.com/vegi.love' className='link-light'><BsInstagram /></a>
                    { intl.locale === 'cs-CZ' && <a target='_blank' rel='noreferrer' href='https://www.youtube.com/channel/UC1yOLeOsdDSa8q2AZcYjRWQ/featured' className='link-light'><BsYoutube /></a> }
                </div>
                <div>&nbsp;</div>
                <div>
                    { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                    <a href='#' className='link-light' onClick={ ( ev ) => { ev.preventDefault(); setShowCookieDetail( true ) }}>{ intl.formatMessage( CookieIntlMessages.customizeLink  )}</a>
                </div>
            </Col>
        </Row>
    </>
}