import React from 'react'
import { User as UserAuth } from '../Auth'

type ContentDefinitionBase = {
    key: string | number
    title: string | React.ReactNode
    imgUrl?: string
    imgAlt?: string
    additionalContent?: React.ReactNode
}

export type ContentDefinitionWithSource = ContentDefinitionBase & {
    source: string
}

export type ContentDefinitionWithLink = ContentDefinitionBase & {
    to: string
}

export type ContentDefinition = ContentDefinitionWithSource | ContentDefinitionWithLink
export type ContentDefinitionList = ContentDefinition[]

export function isContentDefinitionWithLink( item: ContentDefinition ): item is ContentDefinitionWithLink {
    return ( item as ContentDefinitionWithLink ).to !== undefined
}
export function isContentDefinitionWithSource( item: ContentDefinition ): item is ContentDefinitionWithSource {
    return ( item as ContentDefinitionWithSource ).source !== undefined
}

export type UserAPI = {
    contactId: number
    status: string
    auth_meth: string
    roles: string[]
    name?: string
    street: string | null
    city: string | null
    zip: string | null
    country: string | null
    locale: string
    gender: string | null
    email: string
    emailConfirmed: boolean
    mobile: string | null
    mobileConfirmed: boolean
    unregisterToken: string | null
    regpartialDt: Date | null
    registeredDt: Date | null
    unregisteredDt: Date | null
    attendsCourses: number | null
    favorites: string[]
    used:  { [ video: string ]: number }
    modified: Date
    modifiedby: number
    minExpiryDt: Date | null
    maxExpiryDt: Date | null
}

export type UserRegisterFull = Pick<UserAPI, 'email' | 'name' | 'street' | 'city' | 'zip' | 'country' | 'status'> & { pwd: string }

export type UserRegisterPartial = Pick<UserAPI, 'email' | 'name' | 'status'>

export type User = UserAPI & UserAuth

export type SignedUrlData = { 
    queryParams: string
    signedUrl: string
}


export const Currencies = [ 'CZK', 'USD', 'EUR' ]
export type Currency = 'CZK' | 'USD' | 'EUR'
export const CurrencyNames = {
    'USD': '$',
    'EUR': '€',
    'CZK': 'Kč'
}
