import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Button, ButtonProps } from 'react-bootstrap'

export const GoToHomeBtn = ({ children, ...props }: Exclude<ButtonProps, 'name' | 'onClick'> ) => {

    const navigate = useNavigate()

    return <Button type='button' size='sm' style={{ borderRadius: 0 }} { ...props } name='goBack' onClick={ () => navigate( '/', { replace: true } ) }>
        { typeof children !== 'undefined' ? children : <FormattedMessage description='App.GoToHomeBtn.Text' defaultMessage='Go back to home page' /> }
    </Button>
}